<template>
  <div 
    class="eye-container" 
    v-if="(conduta.type == 'oculos' || hasGelatinosa || hasRigida) && hasValue"
  >
    <div class="container-header">
      <h3>{{ title }}</h3>
      <div class="chevron" @click="handleOpen()" v-bind:class="{ rotateUp: !open, rotateDown: open }">
        <ChevronUp />
      </div>
    </div>

    <div class="values" v-if="this.open" v-bind:class="{ openTable: open, closeTable: !open }">
      <b-row v-if="conduta.type == 'oculos' || hasGelatinosa">
        <b-col>
          <b-row>
            <b-col cols="12">
              <span v-if="hasGelatinosa" class="label">Lentes de contato gelatinosa</span>
              <div class="custom-input-group">
                <div class="custom-input">
                  <div class="eye-area">
                    <EyeFill /> D
                  </div>
                  <p class="text-area type-2 with-br">
                    <span>Esférico</span>
                    <input autocomplete="off" type="text" class="form-control text-center" :placeholder="conduta.value.olhoDireitoEsferico" readonly>
                  </p>
                  <p class="text-area type-2 with-br">
                    <span>Cilíndrico</span>
                    <input autocomplete="off" type="text" class="form-control text-center" :placeholder="conduta.value.olhoDireitoCilindrico" readonly >
                  </p>
                  <p class="text-area type-2 with-br">
                    <span>Eixo</span>
                    <input autocomplete="off" type="text" class="form-control text-center" :placeholder="conduta.value.olhoDireitoEixo" readonly >
                  </p>
                  <p class="text-area type-2 with-br">
                    <span>Adição</span>
                    <input autocomplete="off" type="text" class="form-control text-center with-btr" :placeholder="conduta.value.olhoDireitoAdicao" readonly>
                  </p>
                  <p v-if="conduta.type == 'oculos'" class="text-area type-2">
                    <span>DP</span>
                    <input autocomplete="off" type="text" class="form-control text-center with-btr" :placeholder="conduta.value.olhoDireitoDP" readonly>
                  </p>
                  <p v-if="conduta.type == 'lentes-de-contato'" class="text-area type-2">
                    <span>Acuidade Visual</span>
                    <input autocomplete="off" type="text" class="form-control text-center with-btr" :placeholder="conduta.value.olhoDireitoAV" readonly>
                  </p>
                </div>
                <div class="custom-input no-bb">
                  <div class="eye-area">
                    <EyeFill /> E
                  </div>
                  <p class="text-area type-2 with-br">
                    <input autocomplete="off" type="text" class="form-control text-center" :placeholder="conduta.value.olhoEsquerdoEsferico" readonly >
                  </p>
                  <p class="text-area type-2 with-br">
                    <input autocomplete="off" type="text" class="form-control text-center" :placeholder="conduta.value.olhoEsquerdoCilindrico" readonly >
                  </p>
                  <p class="text-area type-2 with-br">
                    <input autocomplete="off" type="text" class="form-control text-center" :placeholder="conduta.value.olhoEsquerdoEixo" readonly >
                  </p>
                  <p class="text-area type-2 with-br">
                    <input autocomplete="off" type="text" class="form-control text-center with-bbr" :placeholder="conduta.value.olhoEsquerdoAdicao" readonly >
                  </p>
                  <p v-if="conduta.type == 'oculos'" class="text-area type-2">
                    <input autocomplete="off" type="text" class="form-control text-center with-btr" :placeholder="conduta.value.olhoEsquerdoDP" readonly>
                  </p>
                  <p v-if="conduta.type == 'lentes-de-contato'" class="text-area type-2">
                    <input autocomplete="off" type="text" class="form-control text-center with-btr" :placeholder="conduta.value.olhoEsquerdoAV" readonly>
                  </p>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row v-if="conduta.value.usarValoresRefracao">
            <b-col>
              <label for="usar-valores-refracao" class="checkbox">
                <input type="checkbox" id="usar-valores-refracao" checked disabled readonly/>
                <span class="checkmark checkmark-disabled">
                  <Check />
                </span>
                Usar valores da refração {{conduta.value.usarValoresRefracao === 'Din' ? 'dinâmica' : 'estática' }}
              </label>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <section v-if="conduta?.value?.marcaDaLente" class="mb-3">
                  <h3>Marca da lente</h3>
                  <span>{{ conduta.value.marcaDaLente }}</span>
              </section>
            </b-col>
          </b-row>
          <b-row >
            <b-col class="mt-3 mx-2">
              <div class="protocol protocols-container" v-for="(protocol, index) in conduta?.value?.protocols" :key="index" >
                  <div class="protocol-header col">
                    <div class="protocol-header__informations">
                      <div class="protocol-name">{{ protocol.name }}</div>
                      <div class="separator mx-2">|</div>
                      <div class="protocol-manufacturer">{{ protocol.manufacturer }}</div>
                    </div>
                  </div>                
                  <div class="protocol-content">
                    <div class="protocol-label">
                      <div class="col-4">Lente recomendada</div>
                      <div class="col">Instruções</div>
                    </div>
                    <div class="protocol-recomendations">
                      <div class="protocol-name col-4">{{ protocol.manufacturer }}</div>
                      <ul class="instructions">
                        <li class="instructions" v-for="(instruction, index) in protocol.instructions" :key="index">
                          {{ instruction }}
                        </li>
                      </ul>
                    </div>
                  </div>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="orientacao-lentes">
              <section v-if="conduta?.value?.orientacao" class="mb-3">
                  <h3>Orientação</h3>
                  <p v-html="conduta.value.orientacao"></p>
              </section>
              <div v-if="conduta.type === 'oculos'">
                <p v-if="conduta.value.materiaPrima">Matéria-prima da lente: <b>{{ conduta.value.materiaPrima }}</b><br/></p>
                <p v-if="conduta.value.tratamentoLente">Tratamento da lente: <b>{{ conduta.value.tratamentoLente.join(', ') }}</b><br/></p>
                <p v-if="conduta.value.tipoLente">Tipo de lente: <b>{{ conduta.value.tipoLente }}</b><br/></p>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>

    <div>
      <b-row v-if="hasRigida">
        <b-col>
          <b-row>
            <b-col cols="12">
              <span class="label">Lentes de contato rigida</span>
              <div class="custom-input-group">
                <div class="custom-input">
                  <div class="eye-area">
                    <EyeFill /> D
                  </div>
                  <p class="text-area type-2 with-br">
                    <span>Modelo</span>
                    <input autocomplete="off" type="text" class="form-control text-center" :placeholder="conduta.value.olhoDireitoModelo" readonly>
                  </p>
                  <p class="text-area type-2 with-br">
                    <span>Curva</span>
                    <input autocomplete="off" type="text" class="form-control text-center" :placeholder="conduta.value.olhoDireitoCurva" readonly >
                  </p>
                  <p class="text-area type-2 with-br">
                    <span>Diametro</span>
                    <input autocomplete="off" type="text" class="form-control text-center" :placeholder="conduta.value.olhoDireitoDiametro" readonly >
                  </p>
                  <p class="text-area type-2 with-br">
                    <span>Grau</span>
                    <input autocomplete="off" type="text" class="form-control text-center with-btr" :placeholder="conduta.value.olhoDireitoGrau" readonly>
                  </p>
                  <p class="text-area type-2 with-br">
                    <span>Fabricante</span>
                    <input autocomplete="off" type="text" class="form-control text-center with-btr" :placeholder="conduta.value.olhoDireitoFabricante" readonly>
                  </p>
                  <p class="text-area type-2">
                    <span>Acuidade Visual</span>
                    <input autocomplete="off" type="text" class="form-control text-center with-btr" :placeholder="conduta.value.olhoDireitoAVRigida" readonly>
                  </p>
                </div>
                <div class="custom-input no-bb">
                  <div class="eye-area">
                    <EyeFill /> E
                  </div>
                  <p class="text-area type-2 with-br">
                    <input autocomplete="off" type="text" class="form-control text-center" :placeholder="conduta.value.olhoEsquerdoModelo" readonly >
                  </p>
                  <p class="text-area type-2 with-br">
                    <input autocomplete="off" type="text" class="form-control text-center" :placeholder="conduta.value.olhoEsquerdoCurva" readonly >
                  </p>
                  <p class="text-area type-2 with-br">
                    <input autocomplete="off" type="text" class="form-control text-center" :placeholder="conduta.value.olhoEsquerdoDiametro" readonly >
                  </p>
                  <p class="text-area type-2 with-br">
                    <input autocomplete="off" type="text" class="form-control text-center with-bbr" :placeholder="conduta.value.olhoEsquerdoGrau" readonly >
                  </p>
                  <p class="text-area type-2 with-br">
                    <input autocomplete="off" type="text" class="form-control text-center with-bbr" :placeholder="conduta.value.olhoEsquerdoFabricante" readonly >
                  </p>
                  <p class="text-area type-2">
                    <input autocomplete="off" type="text" class="form-control text-center with-bbr" :placeholder="conduta.value.olhoEsquerdoAVRigida" readonly >
                  </p>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="orientacao-lentes" >
              <section v-if="conduta?.value?.orientacaoRigida" class="mb-3">
                  <h3>Orientação</h3>
                  <p v-html="conduta.value.orientacaoRigida"></p>
              </section>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>

  </div>
</template>

<script>
import ChevronUp from '@/assets/icons/chevron-up.svg'
import EyeFill from '@/assets/icons/eye-fill.svg'
import Check from '@/assets/icons/check.svg'
import { hasLenteGelatinosa, hasLenteRigida } from '@/utils/documentHelper'

export default {
  name: 'RecordGlassesLens',
  components: {
    EyeFill,
    ChevronUp,
    Check,
  },
  props: {
    title: String,
    conduta: Object
  },

  computed: {
    hasValue() {
      let res = false
      Object.values(this.conduta.value).forEach(value => {
        if(value) res = true
      });
      
      return res
    }
  },

  data() {
    return{
      open: true,
      hasGelatinosa: hasLenteGelatinosa(this.conduta.value),
      hasRigida: hasLenteRigida(this.conduta.value),
    }
  },
  mounted() {
  },

  methods: {
    handleOpen(){
      this.open = !this.open
    }
  },
}
</script>

<style lang="scss" scoped>
.orientacao-lentes {
  margin-top: 16px;
}

.eye-container {
  display: flex;
  flex-direction: column;
  margin: 20px 0;

  .container-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 30px;

    h3 {
      font-size: 18px;
      color: var(--dark-blue);
      font-weight: 600;
    }

    .chevron {
      width: 25px;
      height: 25px;

      > svg {
        cursor: pointer;
        stroke: var(--neutral-600);
        transition: .5s;
      }
    }

    .rotateUp {
        animation: rotateUp .5s;
        -webkit-animation-fill-mode: forwards;
        -moz-animation-fill-mode: forwards;
        animation-fill-mode: forwards;
    }

    .rotateDown {
        animation: rotateDown .5s;
        -webkit-animation-fill-mode: forwards;
        -moz-animation-fill-mode: forwards;
        animation-fill-mode: forwards;
    }

    @keyframes rotateUp {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(180deg);
      }
    }

    @keyframes rotateDown {
      0% {
        transform: rotate(180deg);
      }
      100% {
        transform: rotate(0deg);
      }
    }
  }

  h4 {
    color: var(--dark-blue);
    font-weight: 600;
    font-size: 18px;
  }

  .values {
    display: flex;
    flex-direction: row;
    padding-right: 30px;
  }

  .label {
    color: var(--blue-700);
    font-weight: 600;
  }

  .custom-input-group {
    display: flex;
    flex-direction: column;
    border: 1px solid var(--neutral-300);
    border-radius: 8px;
    margin: 24px 0px 16px 0px;

    .custom-input {
      flex: 1;
      height: 38px;
      border: 8px;
      display: flex;
      flex-direction: row;
      border-bottom: 1px solid var(--neutral-300);
      justify-content: center;

      input[readonly] {
          background-color: var(--neutral-100);
      }

      &.no-bb {
        border-bottom: 0;

        .eye-area {
          border-radius: 0 0 0 8px;
        }
      }

      .eye-area {
        width: 60px;
        background-color: var(--neutral-100);
        border-right: 1px solid var(--neutral-300);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px 0 0 0;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;

        svg {
          width: 16px;
          height: 16px;
          fill: var(--type-active);
          margin-right: 4px;
        }
      }

      .text-area {
        flex: 1;
        line-height: 55px;
        margin-left: 16px;
        font-weight: 400;
        font-size: 16px;
        color: var(--type-active);

        .form-control {
          border: 0 !important;
          border-radius: 0 !important;

          &.with-bbr {
            border-radius: 0 0 8px 0 !important;
          }

          &.with-btr {
            border-radius: 0 8px 0 0 !important;
          }
        }

        &.type-2 {
          position: relative;
          margin-left: 0;
          text-align: center;

          span {
            width: 100%;
            position: absolute;
            top: -19px;
            left: 0;
            font-weight: 700;
            font-size: 12px;
            line-height: 16px;
            color: var(--type-active);
          }
        }

        &.with-br {
          border-right: 1px solid var(--neutral-300) !important;
          margin-bottom: 0px !important;
        }

        &.with-brtr {
          border-radius: 0 8px 0 0 !important;
        }

        &.with-brbr {
          border-radius: 0 0 8px 0 !important;
        }
      }
    }
  }
  .protocols-container {
    border: 1px dashed #305BF2;
    border-radius: 8px;
    
    padding: 4px 2px;

    display: flex;
    flex-direction: column;

    margin-bottom: 5px;

      .protocol-header {
        display:flex;
        flex-direction: row;
        justify-content: space-between;

        font-size: 16px;
        color: var(--dark-blue);

        margin-top: 2px;
        margin-bottom: 10px;

        .protocol-header__informations {
          display: flex;
          flex-direction: row;

          font-weight: 700;
          font-weight: 14px;
        }

        .protocol-header__actions .protocol-remove {
          color: red;
          cursor: pointer;
        }
      }

      .protocol-content {
        display: flex;
        flex-direction: column;

        .protocol-label {
          display: flex;
          flex-direction: row;

          font-size: 14px;
          font-weight: 600;          
          border-bottom: 1px solid #C6CEEB;
        }

        .protocol-recomendations {
          display: flex;
          flex-direction: row;

          font-weight: 400;
          font-size: 16px;

          .instructions {
            flex-direction: column;
          }
        }
      }
  }
}
</style>
